<template>
  <v-col cols="10" lg="7" xl="5" class="signup-wrapper">
    <v-row no-gutters class="mt-10 mb-10" justify="center">
      <img
        src="@assets/landingPage/HG_LOGO.webp"
        alt="hackersgen logo"
        class="hg-logo"
      />
    </v-row>

    <div v-if="!this.showAddSchool">
      <GoBackButton />

      <div class="login-card register-card mb-8">
        <v-row dense>
          <v-col>
            <h2 class="registration-title pb-6">Registrati</h2>
            <v-form v-model="form" class="form-font">
              <v-row dense>
                <v-col cols="12" sm="6" class="pb-0 mb-2">
                  <v-text-field
                    v-model="name"
                    label="Nome *"
                    :rules="[rules.required, rules.minTwoCharacters]"
                    outlined
                    dark
                    background-color="transparent"
                    data-cy="input-first-name"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pb-0 mb-2">
                  <v-text-field
                    v-model="surname"
                    label="Cognome *"
                    :rules="[rules.required, rules.minTwoCharacters]"
                    outlined
                    dark
                    background-color="transparent"
                    data-cy="input-last-name"
                  />
                </v-col>
              </v-row>

              <v-row dense class="mb-2">
                <v-col cols="12" class="mb-0 pb-0">
                  <v-text-field
                    v-model="email"
                    required
                    label="Email *"
                    :rules="[rules.email, rules.required, freeEmailRule]"
                    dark
                    :loading="
                      emailFree === null &&
                      email !== '' &&
                      rules.email(email) === true
                    "
                    background-color="transparent"
                    outlined
                    data-cy="input-email"
                    @keyup="onKeyUpEmail"
                    @keydown="onKeyDownEmail"
                  >
                    <template #append>
                      <v-icon v-if="emailFree === true">
                        mdi-check-decagram
                      </v-icon>
                    </template>
                  </v-text-field>

                  <!-- for mobile use this alert instead of tooltip -->
                  <v-alert
                    v-if="$vuetify.breakpoint.xsOnly && emailFree === false"
                    type="error"
                  >
                    Email già in uso
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense v-if="!Object.keys(this.schoolData).length > 0">
                <v-col cols="12" sm="4" class="mb-8">
                  <v-select
                    v-model="selectedRegion"
                    :rules="getSchoolRules()"
                    :items="regions"
                    label="Regione *"
                    hide-details
                    outlined
                    searchable
                    dark
                    background-color="transparent"
                    data-cy="select-input-region"
                    @change="resetSelectedRegion"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="mb-8">
                  <v-select
                    v-model="selectedProvince"
                    :rules="[rules.required]"
                    :items="selectableProvinces"
                    :disabled="!selectedRegion"
                    label="Provincia *"
                    hide-details
                    outlined
                    searchable
                    dark
                    background-color="transparent"
                    data-cy="select-input-province"
                    @change="resetSelectedProvince"
                  />
                </v-col>
                <v-col cols="12" sm="4" class="mb-8">
                  <v-select
                    v-model="selectedCity"
                    :rules="[rules.required]"
                    :items="selectableCities"
                    :disabled="!selectedProvince"
                    label="Città *"
                    hide-details
                    outlined
                    searchable
                    dark
                    background-color="transparent"
                    data-cy="select-input-city"
                    @change="resetSelectedCity"
                  />
                </v-col>
              </v-row>

              <v-row
                class="mt-0 school"
                v-if="!Object.keys(this.schoolData).length > 0"
              >
                <v-col cols="12" sm="12" class="pb-0">
                  <v-autocomplete
                    v-model="selectedSchool"
                    :rules="[rules.schoolRequired]"
                    :disabled="!selectedCity"
                    :items="selectableSchools"
                    label="Scuola *"
                    item-value="code"
                    :item-text="getFieldText"
                    outlined
                    searchable
                    dark
                    background-color="transparent"
                    data-cy="select-input-school"
                  >
                    <template #item="data">
                      <template>
                        <v-list-item-content>
                          <!-- eslint-disable -->
                          <v-list-item-title
                            v-dompurify-html="getFieldTitle(data.item)"
                          />
                          <!-- eslint-enable -->
                          <v-list-item-subtitle>
                            {{ getFieldSubtitle(data.item) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense class="mt-4">
                <v-col class="d-flex add-school" cols="12">
                  <div
                    v-if="Object.keys(this.schoolData).length > 0"
                    class="white--text font-weight-light position-absolute"
                  >
                    Grazie per averci mandato i dati della tua scuola! Per
                    richieste di supporto contatta
                    <a
                      href="mailto:hackersgen@sorint.com"
                      class="white--text text-decoration-underline"
                    >
                      hackersgen@sorint.com</a
                    >. Gli amministratori potrebbero contattarti per
                    chiarimenti.
                  </div>
                  <a
                    v-else
                    class="white--text font-weight-light text-decoration-underline position-absolute"
                    @click="
                      toggleAddSchool();
                      resetAllSelected();
                    "
                  >
                    Non trovi la tua scuola? Clicca qui per inserire i dettagli!
                  </a>
                </v-col>
              </v-row>

              <v-row dense class="mt-8">
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min, rules.passwordRules]"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password *"
                    hint="Minimo 8 caratteri"
                    background-color="transparent"
                    dark
                    outlined
                    counter
                    data-cy="input-psw"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="confirmPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, samePassword]"
                    :type="showPassword ? 'text' : 'password'"
                    label="Conferma password *"
                    background-color="transparent"
                    dark
                    outlined
                    counter
                    data-cy="input-psw2"
                    @click:append="showPassword = !showPassword"
                  />
                </v-col>
              </v-row>

              <!-- PRIVACY CHECK -->
              <v-row>
                <v-col>
                  <p
                    class="white--text font-weight-light text-body-2 text-justify"
                  >
                    Sottoscrivendo questa form, do il consenso a processare i
                    miei dati personali, come previsto dalla Legge Europea
                    679/2016. Per ulteriori dettagli vedi la
                    <a
                      href="https://www.sorint.com/privacy-policy/"
                      target="_blank"
                      class="colored"
                      rel="noopener"
                    >
                      Sorint.lab Privacy Policy
                    </a>
                  </p>

                  <!--v-checkbox
                    v-model="privacy"
                    disabled
                    label="Accetta Terze Parti *"
                    :rules="[rules.required]"
                    dark
                    hide-details
                    color="#F39200"
                    class="mb-2"
                    data-cy="check-privacy"
                  /-->

                  <p
                    class="white--text font-weight-light text-body-2 text-justify"
                  >
                    <a
                      href="https://www.sorint.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      Sorint.lab S.p.A.</a
                    >
                    dispone di una propria rete di partner autorizzati con il
                    principale obiettivo di fornire il miglior servizio
                    possibile ai propri clienti. Iscrivendoti, accetti che
                    <a
                      href="https://www.sorint.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      Sorint.lab S.p.A.</a
                    >
                    possa condividere parte dei tuoi dati con i suoi partner.
                  </p>

                  <v-checkbox
                    v-model="newsletter"
                    label="Accetta ricezione newsletter"
                    dark
                    hide-details
                    class="mb-2"
                    data-cy="check-newsletter"
                    color="#F39200"
                  />
                  <p
                    class="ml-2 white--text font-weight-light text-body-2 text-justify"
                  >
                    Accetto che
                    <a
                      href="https://www.sorint.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      Sorint.lab S.p.A.</a
                    >
                    e le sue società affiliate possano utilizzare i miei dati
                    personali per inviarmi informazioni su prodotti, servizi,
                    eventi e offerte speciali tramite email.
                  </p>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="d-flex justify-center justify-sm-start white--text font-weight-light"
            cols="12"
          >
            Sei già registrato?
            <a
              class="ml-1 white--text font-weight-medium"
              @click="() => gotoSignin()"
              >Clicca qui per il login
            </a>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              :loading="loading"
              color="#F39200"
              :disabled="checkFormFilled()"
              dark
              class="register-btn"
              @click="submit"
            >
              Registrati
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-else>
      <GoBackRegistration @goBackRegistration="toggleAddSchool" />
      <AddSchool @schoolSaved="saveSchoolData" @schoolAdded="toggleAddSchool" />
    </div>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import api from "@services/axiosInstance"
import GoBackButton from "@cmp-shared/utils/GoBackButton.vue";
import GoBackRegistration from "@cmp-shared/utils/GoBackRegistration.vue";
import AddSchool from "./AddSchool.vue";
import { pushAuthUrl, pushPlatformUrl } from "@/utils/externalRouter";
import { regions } from "@data/regions.js";
import { provinces } from "@data/provinces.js";
import { cities } from "@data/cities.js";

export default {
  name: "SignUpTeacher",
  components: { GoBackButton, GoBackRegistration, AddSchool },
  data() {
    return {
      name: "",
      email: "",
      surname: "",
      schoolName: "",
      schoolSubject: "",
      newsletter: true,
      showPassword: false,
      privacy: true,
      form: false,
      isHeadMaster: false,
      loading: false,
      password: "",
      confirmPassword: "",
      selectableSchools: [],
      selectedRegion: "",
      selectedProvince: "",
      selectedCity: "",
      selectedSchool: "",
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        schoolRequired: (value) =>
          Boolean(value) ||
          "Valore richiesto. Se non trovi la tua scuola, clicca sul link qui sotto.",
        min: (v) => v.length >= 8 || "Minimo 8 caratteri" /* have to change */,
        minTwoCharacters: (v) =>
          v.length >= 2 || "Inserisci almeno due caratteri",
        passwordRules: (v) => {
          const errors = [];
          if (v.length < 8) {
            errors.push("almeno 8 caratteri");
          }
          if (!v.match(/[a-z]/)) {
            errors.push("almeno una lettera minuscola");
          }
          if (!v.match(/[A-Z]/)) {
            errors.push("almeno una lettera maiuscola");
          }
          if (!v.match(/[0-9]/)) {
            errors.push("almeno un numero");
          }
          return errors.length > 0
            ? `La password deve contenere ${errors.join(", ")}`
            : true;
        },
        email: (v) =>
          !v ||
          /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(
            v
          ) ||
          `Indirizzo email non valido`,
      },
      emailTimeout: null,
      emailFree: null,
      showAddSchool: false,
      schoolData: {},
    };
  },
  computed: {
    samePassword() {
      return (
        this.password === this.confirmPassword || "Le password non coincidono"
      );
    },
    freeEmailRule() {
      return this.emailFree !== false || "La mail selezionata è già in uso";
    },
    regions() {
      return regions.map((region) => region.toUpperCase());
    },
    selectableProvinces() {
      return provinces
        .filter(
          (province) =>
            province.region.toUpperCase() == this.selectedRegion.toUpperCase()
        )
        .map((province) => province.name.toUpperCase());
    },
    selectableCities() {
      return cities
        .filter(
          (city) =>
            city.province.toUpperCase() == this.selectedProvince.toUpperCase()
        )
        .map((city) => city.name.toUpperCase());
    },
  },

  watch: {
    async selectedCity() {
      if (this.selectedCity) {
        try {
          this.selectableSchools = await this.getSchoolsByParameter({
            parameter: "city",
            value: this.selectedCity,
          });
        } catch (err) {
          this.selectableSchools = [];
        }
      }
    },
  },
  methods: {
    ...mapActions("auth", ["signUpTeacher", "signUpTeacherAndSchool"]),
    ...mapActions("schools", ["getSchoolsByParameter"]),
    getFieldText(item) {
      return `${item.name} (${item.type}) - ${item.address}`;
    },
    getFieldTitle(item) {
      return `<strong>${item.name}</strong> - ${item.type}`;
    },
    getFieldSubtitle(item) {
      return `${item.address}`;
    },
    gotoSignin() {
      pushAuthUrl();
    },

    async submit() {
      this.loading = true;
      try {
        const userData = {
          name: this.name,
          surname: this.surname,
          email: this.email.trim().toLowerCase(),
          password: this.password,
          newsletter: this.newsletter,
          privacy: this.privacy,
        };

        if (Object.keys(this.schoolData).length === 0) {
          await this.signUpTeacher({
            ...userData,
            school: this.selectedSchool,
          });
          pushPlatformUrl("/waiting");
        } else {
          await this.signUpTeacherAndSchool({
            ...userData,
            school: this.schoolData,
          });
          pushPlatformUrl("/waiting");
        }
      } finally {
        this.loading = false;
      }
    },
    saveSchoolData(data) {
      this.schoolData = data;
    },
    onKeyDownEmail() {
      if (this.rules.email(this.email) === true) {
        this.emailFree = null;
      }
      clearTimeout(this.emailTimeout);
    },
    onKeyUpEmail() {
      this.emailTimeout = setTimeout(this.checkEmailFree, 1500);
    },
    async checkEmailFree() {
      if (this.rules.email(this.email) === true) {
        try {
          const { data } = await api.get("user/check-email", {
            params: { email: this.email.trim().toLowerCase() },
          });
          this.emailFree = data.is_free;
        } catch (err) {
          /* do nothing */
        }
      }
    },
    resetSelectedRegion() {
      this.selectedProvince = "";
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedProvince() {
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    resetSelectedCity() {
      this.selectedSchool = "";
    },
    resetAllSelected() {
      this.selectedRegion = "";
      this.selectedProvince = "";
      this.selectedCity = "";
      this.selectedSchool = "";
    },
    getSchoolRules() {
      if (Object.keys(this.schoolData).length > 0) {
        return [];
      }
      return [this.rules.required];
    },
    checkFormFilled() {
      const userDataMissing =
        !this.name ||
        !this.surname ||
        !this.password ||
        !this.confirmPassword ||
        !this.email ||
        !this.emailFree;

      const schoolDataMissing =
        !this.selectedRegion.trim() ||
        !this.selectedProvince.trim() ||
        !this.selectedCity.trim() ||
        !this.selectedSchool;

      if (Object.keys(this.schoolData).length > 0) {
        return userDataMissing;
      }
      return userDataMissing || schoolDataMissing;
    },
    toggleAddSchool() {
      this.showAddSchool = !this.showAddSchool;
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
h4,
p {
  text-align: left;
  font-family: "Karasuma Gothic", sans-serif;
  font-size: 1.5em;
}

.no-margin-row {
  margin-left: 0;
  margin-right: 0;
}

.register-btn {
  padding: 8px 12px;
  gap: 12px;
  border-radius: 12px;
}

a {
  color: #00a1ff !important;
  text-decoration: none;
}

a:visited {
  color: #00a1ff !important;
  text-decoration: none;
}

a:hover {
  color: #00a1ff;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.signup-wrapper {
  z-index: 2;
}

.colored {
  color: #f39200;
}

.block {
  display: block;
}

.register-card {
  padding: 24px;
  border-radius: 12px;
}

.registration-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 39.06px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
}

.hg-logo {
  height: 62px;
  max-width: 80%;
  object-fit: contain;
  object-position: center;
}

.school {
  margin-bottom: -25px;
}

.add-school {
  position: relative;
}
</style>
